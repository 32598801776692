import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'llamado-revision', loadChildren: () => import('./actualizacion-vehicular/actualizacion-vehicular.module').then(m => m.ActualizacionVehicularModule) },
  { path: 'adn_historia', loadChildren: () => import('./adn-toyota/adn-toyota.module').then(m => m.AdnToyotaModule) }, 
  { path: 'chat', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule) }, 
  { path: 'comparador-vehiculos', loadChildren: () => import('./comparador-vehiculos/comparador-vehiculos.module').then(m => m.ComparadorVehiculosModule) },
  { path: 'comparador-modelos', loadChildren: () => import('./comparador-vehiculos/comparador-vehiculos.module').then(m => m.ComparadorVehiculosModule) }, 
  { path: 'contactenos', loadChildren: () => import('./contactenos/contactenos.module').then(m => m.ContactenosModule) }, 
  { path: 'cookies-alert', loadChildren: () => import('./cookies-alert/cookies-alert.module').then(m => m.CookiesAlertModule) }, 
  { path: 'cotizar/:modelo', loadChildren: () => import('./cotizador/cotizador.module').then(m => m.CotizadorModule) }, 
  { path: 'cotizar', loadChildren: () => import('./cotizador/cotizador.module').then(m => m.CotizadorModule) }, 
  { path: 'cotizador-repuestos', loadChildren: () => import('./cotizador-repuestos/cotizador-repuestos.module').then(m => m.CotizadorRepuestosModule) }, 
  { path: 'encuesta', loadChildren: () => import('./encuestas/encuestas.module').then(m => m.EncuestasModule) }, 
  { path: 'error404', loadChildren: () => import('./error404/error404.module').then(m => m.Error404Module) }, 
  { path: 'footer-toyota', loadChildren: () => import('./footer-toyota/footer-toyota.module').then(m => m.FooterToyotaModule) }, 
  { path: 'gazoo-racing', loadChildren: () => import('./gazoo-racing/gazoo-racing.module').then(m => m.GazooRacingModule) },
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) }, 
  { path: 'mantenimiento', loadChildren: () => import('./mantenimiento/mantenimiento.module').then(m => m.MantenimientoModule) },
  { path: 'mapa-sucursales', loadChildren: () => import('./mapa-sucursales/mapa-sucursales.module').then(m => m.MapaSucursalesModule) }, 
  { path: 'mapa-del-sitio', loadChildren: () => import('./mapa-sitio/mapa-sitio.module').then(m => m.MapaSitioModule) }, 
  { path: 'campanas', loadChildren: () => import('./campanas/campanas.module').then(m => m.CampanasModule) }, 
  //{ path: 'modal-dinamico', loadChildren: () => import('./modal-dinamico/modal-dinamico.module').then(m => m.ModalDinamicoModule) }, 
  { path: 'nav-header', loadChildren: () => import('./nav-header/nav-header.module').then(m => m.NavHeaderModule) }, 
  { path: 'notificaciones', loadChildren: () => import('./notificaciones/notificaciones.module').then(m => m.NotificacionesModule) },
  { path: 'ofertas', loadChildren: () => import('./ofertas/ofertas.module').then(m => m.OfertasModule) },
  { path: 'empieza-tu-imposible', loadChildren: () => import('./olimpiadas/olimpiadas.module').then(m => m.OlimpiadasModule) },
  { path: 'toyota-x-toyota-seminuevos', loadChildren: () => import('./parte-pago/parte-pago.module').then(m => m.PartePagoModule) },
  { path: 'paseo-virtual-modelos', loadChildren: () => import('./paseo-virtual-modelos/paseo-virtual-modelos.module').then(m => m.PaseoVirtualModelosModule) },
  { path: 'paseo-virtual-sucursales', loadChildren: () => import('./paseo-virtual-sucursales/paseo-virtual-sucursales.module').then(m => m.PaseoVirtualSucursalesModule) },
  { path: 'paseo-virtual/:id', loadChildren: () => import('./paseos-virtuales/paseos-virtuales.module').then(m => m.PaseosVirtualesModule) },
  { path: 'modelo/hilux-4.0-at-ng', redirectTo: 'modelo/hilux-4.0-at', pathMatch: 'full' },
  { path: 'modelo/hilux-2.7-at-ng', redirectTo: 'modelo/hilux-2.7-at', pathMatch: 'full' }, 
  { path: 'modelo/:modelo', loadChildren: () => import('./pip/pip.module').then(m => m.PipModule) },
  { path: 'politicas-de-privacidad', loadChildren: () => import('./politicas-privacidad/politicas-privacidad.module').then(m => m.PoliticasPrivacidadModule) },
  { path: 'popup-dinamico', loadChildren: () => import('./popup-dinamico/popup-dinamico.module').then(m => m.PopupDinamicoModule) },
  { path: 'visor-imagenes-miguel', loadChildren: () => import('./visor-imagenes-miguel/visor-imagenes-miguel.module').then(m => m.VisorImagenesMiguelModule) },
  { path: 'repuestos', loadChildren: () => import('./repuestos/repuestos.module').then(m => m.RepuestosModule) },
  { path: 'nosotros', loadChildren: () => import('./sobre-la-empresa/sobre-la-empresa.module').then(m => m.SobreLaEmpresaModule) },
  { path: 'solicitud-repuestos', loadChildren: () => import('./solicitud-repuestos/solicitud-repuestos.module').then(m => m.SolicitudRepuestosModule) },
  { path: 'streaming', loadChildren: () => import('./streaming/streaming.module').then(m => m.StreamingModule) },
  { path: 'sucursales', loadChildren: () => import('./sucursales/sucursales.module').then(m => m.SucursalesModule) },
  { path: 'terminos-condiciones', loadChildren: () => import('./terminos-condiciones/terminos-condiciones.module').then(m => m.TerminosCondicionesModule) },
  { path: 'test-drive', loadChildren: () => import('./test-drive/test-drive.module').then(m => m.TestDriveModule) },
  { path: 'vehiculos/:grupo', loadChildren: () => import('./vehiculos/vehiculos.module').then(m => m.VehiculosModule) },
  { path: 'vehiculos-hibridos', loadChildren: () => import('./vehiculos-hibridos/vehiculos-hibridos.module').then(m => m.VehiculosHibridosModule) },
  { path: 'pip/detalles-tecnicos', loadChildren: () => import('./pip/detalles-tecnicos/detalles-tecnicos.module').then(m => m.DetallesTecnicosModule) },
  { path: 'buscador', loadChildren: () => import('./buscador/buscador.module').then(m => m.BuscadorModule) },
  { path: 'widget-rrss', loadChildren: () => import('./widget-rrss/widget-rrss.module').then(m => m.WidgetRrssModule) },
  { path: '**', redirectTo: 'error404' },
  
  /*Redirección por cambio de término NG
  { path: 'modelo/hilux-worker-ng', redirectTo: 'hilux-worker' },
  { path: 'modelo/hilux-4.0-at-ng', redirectTo: 'hilux-4.0-at' },
  { path: 'modelo/hilux-2.7-at-ng', redirectTo: 'hilux-2.7-at' },
  { path: 'modelo/hilux-lujo-ng', redirectTo: 'hilux-lujo' },
  { path: 'modelo/hilux-full-ng', redirectTo: 'hilux-full' },*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
