<div class="menu-desktop" (onscroll)="myScroll($event)">
    <div id='top-header' class="top-header no-mobile" *ngIf="topHeader===0">
        <div class="container frase">
            <div class="row">
                
                <div class="col-1 font-toyota text-mkt">
                  
                </div>
                <div class="col-11">
                    <div class="container">
                        <div class="row mr-auto iconos-sociales">
                            <a href="https://www.kinto-mobility.com.bo/" target="_blanck" class="is-linnk mund-tyt">Kinto <img [src]="imgexternallink" width="13" height="13" alt="External link" class="external-link"/></a>
                            <a routerLink="/gazoo-racing" class="is-linnk mund-tyt">Gazoo Racing</a>
                            <a routerLink="/vehiculos-hibridos" class="is-linnk mund-tyt">Vehículos Híbridos</a>
                            <a routerLink="/campanas/plan-renuevate" target="_blanck" class="is-linnk mund-tyt">Renuévate ∞</a>
                            <a routerLink="/adn_historia" class="is-linnk mund-tyt">Mundo Toyota</a>
                            <!--<a routerLink="/nosotros" class="is-linnk mund-tyt">Sobre Nosotros</a>-->
                            <a href="https://noticias.toyosa.com/" target="_blanck" class="is-linnk news">Noticias <img [src]="imgexternallink" width="13" height="13" alt="External link" class="external-link"/></a>
                            <!--<a href="https://www.facebook.com/ToyotaBoliviaoficial" target="_blanck" class="is-linnk"><img src="../../assets/fonts/header/facebook.svg" alt="Facebook" class="rs-icon"/></a>
                            <a href="https://www.instagram.com/toyota_bolivia/" target="_blanck" class="is-linnk"><img src="../../assets/fonts/header/instagram.svg" alt="Instagram" class="rs-icon"/></a>
                            <a href="https://www.youtube.com/channel/UCfYsmVjP96GrQs0_XbdZoug" target="_blanck" class="is-linnk"><img src="../../assets/fonts/header/youtube.svg" alt="Youtube" class="rs-icon"/></a>
                            <a href="https://www.linkedin.com/company/toyota-bolivia/" target="_blanck" class="is-linnk"><img src="../../assets/fonts/header/icono_linkedin.svg" alt="LinkedIn" class="rs-icon"/></a>
                            <a href="https://www.tiktok.com/@toyota_bolivia" target="_blanck" class="is-linnk"><img src="../../assets/fonts/header/icono_tiktok.svg" alt="tiktok" class="rs-icon"/></a>
                            <a href="tel:591800175200" target="_blanck" class="is-linnk">
                                <img src="../../assets/icons/header/Icono_contact_header.svg" alt="Contact center" class="icons-cc"/>
                            </a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id='header' class="header no-mobile">
        <div class="menu-fixed" >
            <div class="container header-menu" (mouseenter)="viewModelsBlock = false">
                <nav class="navbar navbar-expand-lg navbar-light"  (mouseenter)="search = ''">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <!-- Nuevo Diseño menú
                        <a class="navbar-brand top-menu" routerLink="" *ngIf="topHeader===0">  
                    </a>-->
         

                    <a class="navbar-brand" routerLink="" *ngIf="topHeader>0">
                        <img [src]="logo" width="64" height="40" alt="Logo Toyota" class="logo"/>
                    </a> 
                    <a class="navbar-brand" routerLink="" *ngIf="topHeader===0">
                        <img [src]="logo" width="64" height="40" alt="Logo Toyota" class="logo"/>
                    </a>           
                    
                    <div class="navbar-collapse" id="navbarTogglerDemo02">
                        <ul class="col-12 navbar-nav ml-4 mt-2 mt-lg-0 mr-lg-4 ml-lg-8 justify-content-end">
                            <li ngbDropdown class="nav-item dropdown">
                                <a class="nav-link item-toyota" id="vehiculos" ngbDropdownToggle (click)="viewModels()">Vehículos</a>                  
                            </li>
                            <li class="nav-item  menu-seminuevos">
                                <a class="nav-link item-toyota" (click)="noViewModels()" href="https://www.seminuevos.toyota.bo/"><span>Seminuevos</span>  <img [src]="imgexternallinkblack" width="12" height="12" class="external-link_menu" style="margin-top: 5px;"/></a>
                            </li>
                            <li ngbDropdown class="nav-item dropdown">
                                <a class="nav-link item-toyota" id="dropdownBasic1"  data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle (click)="noViewModels()">Servicios</a>
                                <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu submenu-toyota">
                                    <!--<li class="dropdown-item sub-item_toyota"><a class="nav-link" routerLink="/mantenimiento">Mantenimientos y Reparaciones</a></li>-->
                                    <!--li class="dropdown-item sub-item_toyota"><a class="nav-link" href="https://www.toyosa.com/servicio-tecnico" target="_blanck" style="display: flex; align-items: center;">Agendado de Citas   <img src="../../assets/fonts/header/external-link-black.svg" class="external-link_menu"/></a></li>-->
                                    <li class="dropdown-item sub-item_toyota"><a class="nav-link" href="https://www.toyosa.com/servicio-tecnico/cotizar-mantenimientos" target="_blanck" style="display: flex; align-items: center;"> Cotizar Mantenimientos  <img [src]="imgexternallinkblack" width="14" height="14" class="external-link_menu"/></a></li>
                                    <li class="dropdown-item sub-item_toyota"><a class="nav-link" href="https://www.toyosa.com/servicio-tecnico" target="_blanck" style="display: flex; align-items: center;"> Agendar Cita  <img [src]="imgexternallinkblack" width="14" height="14" class="external-link_menu"/></a></li>
                                    <li class="dropdown-item sub-item_toyota"><a class="nav-link" routerLink="/llamado-revision">Campañas</a></li>
                                    <li class="dropdown-item sub-item_toyota"><a class="nav-link" href="https://www.toyosa.com/takata" target="_blanck" style="display: flex; align-items: center;"> Takata  <img [src]="imgexternallinkblack" width="14" height="14" class="external-link_menu"/></a></li>
                                    <li class="dropdown-item sub-item_toyota"><a class="nav-link" href="https://www.toyosa.com/tienda-virtual" target="_blanck" style="display: flex; align-items: center;"> Repuestos  <img [src]="imgexternallinkblack" width="14" height="14" class="external-link_menu"/></a></li>
                                    
                                </ul>
                            </li>
                            <!--<li ngbDropdown class="nav-item">
                                <a class="nav-link item-toyota" id="mundotoyota" ngbDropdownToggle (click)="noViewModels()">Quiénes Somos</a>
                                <ul ngbDropdownMenu aria-labelledby="mundotoyota" class="submenu-toyota">
                                    <li class="dropdown-item sub-item_toyota"><a class="nav-link" href="/nosotros">Sobre Nosotros</a></li>
                                </ul>
                            </li>-->
                            <li class="nav-item">
                                <a class="nav-link item-toyota" (click)="noViewModels()" routerLink="/sucursales">Sucursales</a>
                            </li>
                            <!--<li class="nav-item">
                                <a routerLink="/cotizar" class="nav-link item-toyota_add item-toyota" (click)="noViewModels()" style="align-items: center; display: flex;"><span>Cotizar</span></a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/comparador-modelos" class="nav-link item-toyota_add item-toyota" (click)="noViewModels()">Comparar</a>
                            </li>-->
                            
                            
                            <li class="nav-item">
                                <a class="nav-link " (click)="noViewModels()" routerLink="/contactenos">Contáctanos</a>
                            </li>
                            <li class="ml-2">
                                <a routerLink="buscador" class="linkb">
                                    <img src="../../assets/icons/header/buscador-rojo.svg" width="18" height="18" class="ibuscar"/>
                                </a>
                                
                            </li>
                        </ul>
                        <!--
                        <ul class="col-5 navbar-nav ml-auto mt-2 mt-lg-0 mr-lg-4 justify-content-end">
                            <li class="nav-item">
                                <a routerLink="/cotizar" class="nav-link item-toyota_add" (click)="noViewModels()" style="align-items: center; display: flex;"><span>Cotizar</span></a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/comparador-modelos" class="nav-link item-toyota_add" (click)="noViewModels()">Comparar</a>
                            </li>
                            <li class="ml-2">
                                <a routerLink="buscador" class="linkb">
                                    <img src="../../assets/icons/header/buscador-rojo.svg" class="ibuscar"/>
                                </a>
                                
                            </li>
                        </ul>      
                        -->
                    </div>
                    
                </nav>
            </div>

            <div class="row menu-models" *ngIf="viewModelsBlock && !search">
                <a class="nav-close" (click)="viewModelsBlock = false">
                    X
                </a>
                <div class="container menu-models_groups" id="submenu-modelos">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event)" class="nav-tabs menu-models_items">
                        <li *ngFor="let nombre of grupoModelos; index as i" [ngbNavItem]="i" (click)="obtenerItems(nombre)">
                            <a ngbNavLink class="nombre-grupo item-toyota font-toyota">{{nombre}}</a>
                            <ng-template ngbNavContent class="container">
                                <div class="container">
                                <div *ngIf="itemsGrupo" class="font-toyota submenu-modelos_title">
                                    <img src="../../assets/fonts/header/arrow.svg" class="icon-models"/> 
                                    <div class="separator-vertical_title"></div> 
                                    <span class="submenu-modelos_title__data">{{nombre}}</span>  
                                </div>
                                <div class="row models-items">
                                    
                                        <div *ngFor="let items of itemsGrupo; index as mod" class="col-3 mt-4 mb-4">
                                            <a routerLink="modelo/{{items.modelo_url}}" (click)="noViewModels()">
                                                <img [src]="items.url_imagen" class="models-items_img"/>
                                            </a>
                                            <div class="row pl-1 mt-2">
                                                <h3 class="col-12 items-name">{{items.nombre_modelo}}</h3>
                                                <div class="col-12">
                                                    <div class="separator-horizontal"></div>
                                                </div>
                                            </div>
                                            <div class="row pl-3 mt-3">
                                                <div class="col-5">
                                                    <a class="items-link font-toyota" routerLink="modelo/{{items.modelo_url}}" (click)="noViewModels()">EXPLORAR</a>
                                                </div>
                                                <div class="col-1 separator-menu">|</div>
                                                <!--<div class="separator-vertical"></div>-->
                                                <div class="col-5">
                                                    <a class="items-link font-toyota" routerLink="/cotizar/{{items.modelo_url}}" (click)="noViewModels()">COTIZAR</a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>  
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>    
                </div>
            </div>
        
            <div class="row menu-search" *ngIf="search">
                <app-buscador [search]="search" class="container"></app-buscador>
            </div>
        </div>
    </div>
</div>

<app-mobile class="mobile"></app-mobile>
